// partners.ts

export interface Partner {
  href: string;
  src: string;
  alt: string;
  textKey: string;
  volume?: string;
}

export const partnerList: Partner[] = [
  {
    href: 'https://www.bitmart.com/trade/en-US?symbol=DD_USDT',
    src: '/images/partners/bitmart.png',
    alt: 'bitmart',
    textKey: 'cryptocurrency-exchange',
    volume: '',
  },
  {
    href: 'https://p2pb2b.com/trade/DD_USDT/',
    src: '/images/partners/p2b.png',
    alt: 'p2b',
    textKey: 'cryptocurrency-exchange',
    volume: '',
  },
  {
    href: 'https://www.digifinex.com/en-ww/trade/USDT/DD',
    src: '/images/partners/digifinex.png',
    alt: 'digifinex',
    textKey: 'cryptocurrency-exchange',
    volume: '',
  },
  {
    href: 'https://www.biconomy.com/exchange/DD_USDT',
    src: '/images/partners/biconomy.png',
    alt: 'biconomy',
    textKey: 'cryptocurrency-exchange',
    volume: '',
  },
  {
    href: 'https://www.mountainwolf.com/',
    src: '/images/partners/mountain.png',
    alt: 'mountain',
    textKey: 'digital-payments',
    volume: '',
  },
  {
    href: 'https://www.coingecko.com/en/coins/diment-dollar',
    src: '/images/partners/coingecko.png',
    alt: 'coingecko',
    textKey: 'powered-by',
    volume: '',
  },
  {
    href: 'https://coinmarketcap.com/currencies/diment-dollar/',
    src: '/images/partners/coinmarketcap.png',
    alt: 'coinmarketcap',
    textKey: 'powered-by',
    volume: '',
  },
];
