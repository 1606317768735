// Card.tsx

import { H4 } from '@/components/Headings';
import { TextDefault } from '@/components/Texts';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import styles from '@/styles/cardShine.module.css';

interface CardProps {
  imageSrc: string;
  headingKey: string;
  descParts: string;
  variant?: 'pre-backed' | 'pre-audit' | 'value-security';
}

const Card: React.FC<CardProps> = ({
  imageSrc,
  headingKey,
  descParts,
  variant,
}) => {
  const { t } = useTranslation('main');
  const gradient =
    variant === 'pre-audit'
      ? 'linear(to-t, rgba(10, 75, 114, 1), rgba(10, 75, 114, 0))'
      : variant === 'value-security'
      ? 'linear(to-t, rgba(48, 48, 48, 1), rgba(48, 48, 48, 0))'
      : 'none';

  return (
    <div className={styles.cardShine}>
      <Stack
        w={'full'}
        maxW={{ base: 'full', sm: 469 }}
        minH={{ base: 'md', sm: 'lg', md: 'xl' }}
        bgImage={imageSrc}
        bgSize={'cover'}
        bgPosition={'center'}
        color={'white'}
        justify={'end'}
        align={'center'}
        textAlign={'center'}
        position='relative'
      >
        <Stack
          align={'center'}
          bgGradient={gradient}
          px={{ base: 6, xl: 8 }}
          w={'full'}
          minH={{ base: 'full', lg: 270 }}
          py={8}
        >
          <Stack
            w={'full'}
            align={'center'}
            maxW={{ base: 298, sm: 500, md: 'full' }}
            spacing={2}
          >
            <H4>{t(`home.about-diment.headings.${headingKey}`)}</H4>
            <TextDefault
              fontSize={{ base: '12px', sm: '16', md: '15' }}
              fontWeight={400}
            >
              {t(`home.about-diment.desc.${headingKey}.part1`)}
              <TextDefault
                fontSize={{ base: '12px', sm: '16', md: '15' }}
                as='span'
                fontWeight={700}
              >
                {t(`home.about-diment.desc.${headingKey}.boldPart`)}
              </TextDefault>
              {t(`home.about-diment.desc.${headingKey}.part2`)}
            </TextDefault>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default Card;
