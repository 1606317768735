import { Divider, Flex, Image, Stack } from '@chakra-ui/react';
import { partnerList, Partner } from './constants/partners';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { colors } from '@/styles/theme/colors';
import { TextSmall } from '@/components/Texts';
import Marquee from 'react-fast-marquee';
import { useState, useEffect } from 'react';

export const fetchPartnerVolumes = async () => {
  try {
    const response = await fetch(
      'https://exchange-balances.diment.io/trade/volume',
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Failed to fetch partner volumes:', error);
    return null;
  }
};

const PartnerTape = () => {
  const { t } = useTranslation('main');
  const [partners, setPartners] = useState<Partner[]>(partnerList);

  useEffect(() => {
    const fetchData = async () => {
      const lastFetchData = localStorage.getItem('partnerVolumes');
      const lastFetchTime = localStorage.getItem('lastFetchTime');
      const now = new Date().getTime();

      if (lastFetchData && lastFetchTime && now - parseInt(lastFetchTime, 10) < 24 * 60 * 60 * 1000) {
        setPartners(JSON.parse(lastFetchData));
      } else {
        const data = await fetchPartnerVolumes();
        if (data) {
          const updatedPartners = partners.map((partner) => {
            const apiData = data.find(
              (d: any) => d.exchange.toLowerCase() === partner.alt,
            );
            return {
              ...partner,
              volume: apiData
                ? Number(
                    parseFloat(apiData.totalVolume).toFixed(0),
                  ).toLocaleString('en-US')
                : partner.volume,
            };
          });
          setPartners(updatedPartners);
          localStorage.setItem('partnerVolumes', JSON.stringify(updatedPartners));
          localStorage.setItem('lastFetchTime', now.toString());
        }
      }
    };

    fetchData();
  }, []);

  const renderPartnerImages = (direction: 'left' | 'right') => (
    <Marquee speed={40} direction={direction} pauseOnHover gradient={false}>
      {partners.map((partner, index) => (
        <Link key={index} href={partner.href} target='_blank'>
          <Stack align={'center'} w={'full'} textAlign={'center'} pb={8}>
            <Image
              filter={'grayscale(100%)'}
              _hover={{
                filter: 'grayscale(0%)',
              }}
              w={{ base: 200, md: 200 }}
              minWidth={{ base: 200, md: 200 }}
              style={{ objectFit: 'contain' }}
              loading='lazy'
              src={partner.src}
              alt={partner.alt}
              marginX={4}
              height={100}
              p={{ base: 0, md: 2 }}
            />
            <TextSmall
              mt={'-20px'}
              fontSize={'xs'}
              fontWeight={700}
              color={colors.diment.darkBlue}
            >
              {t(`home.partner-tape.${partner.textKey}`)}
            </TextSmall>
            <TextSmall
              fontSize={'sm'}
              fontWeight={700}
              color={'#000000'}
              _hover={{
                color: '#0A4B72',
              }}
            >
              {partner.volume &&
                `${t('home.partner-tape.daily-volume')}: ${partner.volume}`}
            </TextSmall>
          </Stack>
        </Link>
      ))}
    </Marquee>
  );

  return (
    <Stack color={colors.diment.darkBlue} w={'full'} align={'center'}>
      {/* <H4>{t('home.partners')}</H4> */}
      <Flex
        h='full'
        overflowX='hidden'
        position='relative'
        w='full'
        maxW='8xl'
        direction='column'
        gap={6}
      >
        {renderPartnerImages('left')}
        {/* {renderPartnerImages('right')} */}
      </Flex>
    </Stack>
  );
};

export default PartnerTape;
